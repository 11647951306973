import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2L from 'components/typography/h2'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import YouTube from 'components/youtube'
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight'
import Layout from 'components/layout'
import Content from 'components/content'
import Module from 'components/modules/module'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const breadcrumbs = [
  {
    name: 'Home',
    schemaName: 'Hutson Inc',
    link: '/',
  },
  {
    name: 'Hutson Customer Portal',
    link: '/hutson-customer-portal/',
  },
  {
    name: 'Creating an Account',
    link: '/hutson-customer-portal/creating-an-account/',
  },
]

const CreatingAnAccount = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Hutson Customer Portal - Creating an Account | Hutson Inc</title>
        <meta
          name='description'
          content='Learn how to create an account with the Hutson Hutson Customer Portal.'
        />
        <meta name='keywords' content='Hutson Customer Portal, create, account' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            'name': 'How to Create an Account - Hutson Customer Portal',
            'description':
              'Learn how to create an account with the Hutson Customer Portal. Visit our website to create an account.',
            'thumbnailUrl': 'https://i.ytimg.com/vi/OBdMWRSd9z4/hqdefault.jpg',
            'uploadDate': '2018-09-21T15:07:42+00:00',
            'duration': 'PT59S',
            'embedUrl': 'https://www.youtube.com/embed/OBdMWRSd9z4',
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <HeroImage title='Creating an Account' image={heroImage} breadcrumbs={breadcrumbs} />

      <Content>
        <YouTube videoId='OBdMWRSd9z4' />

        <Controls>
          <NavControl direction='prev' />
          <NavControl direction='next' title='Next tutorial'>
            <Link to='/hutson-customer-portal/viewing-past-invoices/'>
              <span className='nav-control-label'>Next:&nbsp;</span>
              <span className='nav-control-title'>How to View Past Invoices</span>
              <FaCaretRight aria-hidden='true' focusable='false' role='presentation' />
            </Link>
          </NavControl>
        </Controls>

        <H2L>Video Transcription</H2L>
        <Transcript>
          To get logged in to, or to set up and account on the Hutson Hutson Customer Portal, you
          first need to navigate in your browser to hutsoninc.com. Once there, you'll scroll to the
          bottom of the site and click 'Customer Portal' underneath the products and support tab.
          The Customer Portal will open up in a new tab on your browser. There you will be prompted
          with two options: log in to your account and order parts as a guest. To get the full
          features and use the Hutson Hutson Customer Portal, you will want to create an account. To
          create an account, we'll need to click 'Login to your account'. You next need to click the
          'Sign up' tab in the top right-hand corner. Next, you must complete the required fields
          indicated by a red asterisk. Once completed, you'll receive a call from a Hutson associate
          in the next 24 hours. This call will confirm your account number and link your data in our
          business system. Once confirmed, your account will be open and be ready for use.
        </Transcript>
      </Content>

      <Module
        text="Save time by adding the Hutson Customer Portal to your phone's home screen"
        buttonText='Learn more'
        link='/blog/hutson-customer-portal-phone-home-screen/'
        image='/uploads/hutson-customer-portal-home-screen.jpg'
      />
    </Layout>
  )
}

const Controls = styled.div`
  margin: 30px 0 40px;

  @media (min-width: 800px) {
    margin-bottom: 60px;
  }
`

const NavControl = styled.div`
  display: block;
  padding-top: 15px;
  text-align: left;
  width: 100%;

  a {
    color: #7d7d7d;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  .nav-control-title,
  .nav-control-label {
    font-size: 1.2em;
    vertical-align: middle;
  }

  .nav-control-title {
    text-decoration: underline;
  }

  svg {
    display: none;
  }

  @media (min-width: 800px) {
    display: inline-block;
    padding-top: 0;
    text-align: ${props => (props.direction === 'next' ? 'right' : 'left')};
    width: 50%;

    .nav-control-label {
      display: none;
    }

    .nav-control-title {
      text-decoration: none;
    }

    svg {
      color: #aaa;
      display: inline-block;
      font-size: 1em;
      margin: 0 10px;
      vertical-align: middle;
    }
  }
`

const Transcript = styled.p`
  margin: 0;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "customer-portal-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default CreatingAnAccount
